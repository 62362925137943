import React, {useState} from 'react';
import {FaBars, FaTimes, FaFacebook, FaLinkedin, FaZoom, FaTeams} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import { Link } from 'react-scroll';
import Logo from '../assets/Logo.png'

const Navbar = () => {
    const [nav, setNav] = useState (false)
    const handleClick = () => setNav (!nav)

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-slate-600 text-white'>
        <div>
            <img src={Logo} alt='Logo Image' style={{width: '150px '}} />
        </div>
        {/* /* menu line */}
        <ul className='hidden md:flex'>
            <li>
            <Link to="home" smooth={true} duration={500}>
                Home
         </Link>
            </li>
            <li>
            <Link to="about" smooth={true} duration={500}>
                About
         </Link>
            </li>
            <li>
            <Link to="skills" smooth={true} duration={500}>
                Skills
         </Link>
            </li>
            <li>
            <Link to="projects" smooth={true} duration={500}>
                Projects
         </Link>
            </li>
            <li>
            <Link to="contact" smooth={true} duration={500}>
                Contact
         </Link>
            </li>
        </ul>
    

        {/* /* Hamburger line */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars /> : <FaTimes />}
        </div>
        {/* /* Mobile menu line */}
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-slate-600 flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl'>
            <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                Home
         </Link>
            </li>
            <li className='py-6 text-4xl'>
            <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                About
         </Link>
            </li>
            <li className='py-6 text-4xl'>
            <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                Skills
         </Link>
            </li>
            <li className='py-6 text-4xl'>
            <Link onClick={handleClick} to="projects" smooth={true} duration={500}>
                Projects
         </Link>
            </li>
            <li className='py-6 text-4xl'>
            <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                Contact
         </Link>
            </li>
        </ul>
        {/* /* Social icons line */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#0A66C2]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="https://www.linkedin.com/in/kingsley-nzekwe-772471111/">
                        Linkedin <FaLinkedin size={30}/>
                    </a>
                </li>
                <li className='w-[160px h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#1877F2]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="/">
                        Facebook <FaFacebook size={30}/>
                    </a>
                </li>                            
                <li className='w-[160px h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-red-600'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="https://myaccount.google.com/personal-info">
                        Email <HiOutlineMail size={30}/>
                    </a>
                </li>
                <li className='w-[160px h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-green-600'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="/">
                        Resume <BsFillPersonLinesFill size={30}/>
                    </a>
                </li>
            </ul>

        </div>


    </div>
  )
}

export default Navbar