import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-gray-900 flex justify-center items-center p-4'>
      <form method='POST' action="https://getform.io/f/ab5035c6-dcb6-492b-ac8f-a07a0b7c65e1" className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8'>
          <p className='text 4xl front-bold inline border-b-4 border-pink-600 text-gray-300'>Contact</p>
        </div>
        <input className='bg-[#ccd6f6]' type='text' placeholder='Name' name='name' />
        <input className='my-4 p-2 bg-[#ccd6f6]' type='email' placeholder='Email' name='email' />
        <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Message' ></textarea>
        <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 my-8 mx-auto items-center'>Let's Connect!</button>
      </form>
    </div>
  )
}

export default Contact