import React from 'react'
import FoodDelivery from '../assets/Food-Delivery-Background.jpg'
import DashBoard from '../assets/Dashboard-Background.jpg'


const Projects = () => {
  return (
    <div name='projects' className='w-full md:h-screen text-gray-300 bg-gray-900'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl front-bold inline border-b-4 text-gray-300 border-pink-600'> Projects</p>
                <p className='py-6'> My recent work</p>
            </div>

       {/**container for projects */}   
        < div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4"> 

         {/**Grid Item */} 
        <div style={{ backgroundImage: `url(${FoodDelivery})` }}  className="shadow-lg shadow-[#040c16] group container rounded-md 
              flex justify-center text-center items-center mx-auto content-div ">

        {/**Hover Effects  */}
            <div className='opachity-0 group-hover:opachity-100'>
                <span className='text-2xl font-bold text-white tracking-wider'>
                    Food Delivery React JS Application
                </span>
                <div className='pt-8 text-center'>
                    <a href='https://food-delivery-app-food-delivery-app-com.preview-domain.com/'>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                     </a>
                </div>   
            </div>
        </div>

        <div style={{ backgroundImage: `url(${DashBoard})` }}  className="shadow-lg shadow-[#040c16] group container rounded-md 
              flex justify-center text-center items-center mx-auto content-div ">

        {/**Hover Effects  */}
            <div className='opachity-0 group-hover:opachity-100'>
                <span className='text-2xl font-bold text-white tracking-wider'>
                    DashBoard React JS Application
                </span>
                <div className='pt-8 text-center'>
                    <a href='https://dashboard-app-com.preview-domain.com/'>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                     </a>
                </div>   
            </div>
        </div>
    </div>
</div>
</div>

)}



export default Projects;